
(function ($) {
    'use strict';
      var imJs = {
        m: function (e) {
            imJs.d();
            imJs.methods();
        },
        d: function (e) {
            this._window = $(window),
            this._document = $(document),
            this._body = $('body'),
            this._html = $('html')
        },
        methods: function (e) {
            imJs.swiperActivation();
            imJs.stickyHeader();
            imJs.backToTopInit();
            imJs.searchOpton();
            imJs.menuActive();
            imJs.popupMobileMenu();
            imJs.preloader();
            imJs.slideToggle();
        },
        swiperActivation: function (){
          $(document).ready(function() {
            var swiper = new Swiper(".hero-banner", {
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              loop:true,
              loopFillGroupWithBlank: true,              
              pagination: {
                el: ".swiper-pagination",
                type: "fraction",
              },
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
              autoplay: {
                delay: 4000,
              },
            });
          });
          $(document).ready(function() {
            var swiper = new Swiper(".silder1", {
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              loop:true,
              loopFillGroupWithBlank: true,
              // pagination: {
              //   el: ".swiper-pagination",
              //   type: "fraction",
              // },
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
              autoplay: {
                delay: 4000,
              },
            });
          });
          $(document).ready(function() {
            var swiper = new Swiper(".recent-posts-silder", {
              slidesPerView:3,
              spaceBetween: 10,
              slidesPerGroup: 1,
              loop: true,
              variableWidth: true,
              loopFillGroupWithBlank: true,
              // centeredSlides: true,

              // pagination: {
              //   el: ".swiper-pagination",
              //   type: "fraction",
              // },
              // navigation: {
              //   nextEl: ".swiper-button-next",
              //   prevEl: ".swiper-button-prev",
              // },
              autoplay: {
                delay:1000,
              },              
              breakpoints: {
                1500: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                }
              },
            });
          });
          $(document).ready(function() {
            var swiper = new Swiper(".university-silder", {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup:1,
              loop: true,
              loopFillGroupWithBlank: true,
              // pagination: {
              //   el: ".swiper-pagination",
              //   type: "fraction",
              // },
               navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
              // navigation: {
              //   el: '.swiper-pagination',
              //   clickable: true,
              //   dynamicBullets: true,
              // },
              autoplay: {
                delay: 3000,
              },
              breakpoints: {
                1500: {
                  slidesPerView: 4,
                },
                1300: {
                  slidesPerView: 4,
                },
                991: {
                  slidesPerView: 3,
                  
                },
                767: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                }
              },
            });
          });
        },
        stickyHeader: function (e) {
          // $(window).scroll(function () {
          //     if ($(this).scrollTop() > 150) {
          //         $('.header--sticky').addClass('sticky')
          //     } else {
          //         $('.header--sticky').removeClass('sticky')
          //     }
          // })
          $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();
  
            if (scroll >= 80) {
              $(".header-one").addClass("nav-fixed");
            } else {
              $(".header-one").removeClass("nav-fixed");
            }
          })
        },
        slideToggle: function () {
        $(document).ready(function(){
          $(".category").click(function(){
            $(".vertical-menu-list").slideToggle("slow");
            $('.category').toggleClass('toggle-arrow');
           });
         });
        },

        backToTopInit: function () {
          $(document).ready(function(){
          "use strict";
      
          var progressPath = document.querySelector('.progress-wrap path');
          var pathLength = progressPath.getTotalLength();
          progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
          progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
          progressPath.style.strokeDashoffset = pathLength;
          progressPath.getBoundingClientRect();
          progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';		
          var updateProgress = function () {
            var scroll = $(window).scrollTop();
            var height = $(document).height() - $(window).height();
            var progress = pathLength - (scroll * pathLength / height);
            progressPath.style.strokeDashoffset = progress;
          }
          updateProgress();
          $(window).scroll(updateProgress);	
          var offset = 50;
          var duration = 550;
          jQuery(window).on('scroll', function() {
            if (jQuery(this).scrollTop() > offset) {
              jQuery('.progress-wrap').addClass('active-progress');
            } else {
              jQuery('.progress-wrap').removeClass('active-progress');
            }
          });				
          jQuery('.progress-wrap').on('click', function(event) {
            event.preventDefault();
            jQuery('html, body').animate({scrollTop: 0}, duration);
            return false;
          })
          
          
        });
  
        },
        searchOpton:function(){
          $(document).on('click', '#search', function () {
            $(".search-input-area").addClass("show");
            $("#anywhere-home").addClass("bgshow");
          });
          $(document).on('click', '#close', function () {
            $(".search-input-area").removeClass("show");
            $("#anywhere-home").removeClass("bgshow");
          });
          $(document).on('click', '#anywhere-home', function () {
            $(".search-input-area").removeClass("show");
            $("#anywhere-home").removeClass("bgshow");
          });
        },
        preloader:function(){
          window.addEventListener('load',function(){
            document.querySelector('body').classList.add("loaded")  
          });          
        },
        menuActive:function(){
          $(document).on('click', '#menu-btn', function () {
            $("#side-bar").addClass("show");
            $("#anywhere-home").addClass("bgshow");
          });
          $(document).on('click', '.close-icon-menu', function () {
            $("#side-bar").removeClass("show");
            $("#anywhere-home").removeClass("bgshow");
          });
          $(document).on('click', '#anywhere-home', function () {
            $("#side-bar").removeClass("show");
            $("#anywhere-home").removeClass("bgshow");
          });
          $(document).on('click', '.onepage .mainmenu li a', function () {
            $("#side-bar").removeClass("show");
            $("#anywhere-home").removeClass("bgshow");
          });
        },
        popupMobileMenu: function (e) {
          $('.hamberger-button').on('click', function (e) {
              $('.popup-mobile-menu').addClass('active');
          });

          $('.close-menu').on('click', function (e) {
              $('.popup-mobile-menu').removeClass('active');
              $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').siblings('.submenu, .rts-megamenu').removeClass('active').slideUp('400');
              $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').removeClass('open')
          });

          // nav menu
          $('.body-mobile .mainmenu .has-droupdown > a, .body-mobile .mainmenu .with-megamenu > a').on('click', function (e) {
              e.preventDefault();
              $(this).siblings('.submenu, .rts-megamenu').toggleClass('active');
              $(this).toggleClass('open')
          })
          // nav menu end

          $('.popup-mobile-menu').on('click', function (e) {
              e.target === this && $('.popup-mobile-menu').removeClass('active') && $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').siblings('.submenu, .rts-megamenu').removeClass('active').slideUp('400') && $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').removeClass('open');
          });

          $('.one-page-vavigation-popup .mainmenu li > a').on('click' , function (e) {
              e.preventDefault();
              $('.popup-mobile-menu').removeClass('active');
              $('.popup-mobile-menu .mainmenu li > a').siblings('.submenu').removeClass('active');
          })
        },
        // pne page scroll top
    }
  
    imJs.m();
  })(jQuery, window)  

  //Sandeep

  $('.close-menu').on('click', function (e) {
    $('.popup-mobile-menu').removeClass('active');
    $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').siblings('.submenu, .rts-megamenu').removeClass('active').slideUp('400');
    $('.popup-mobile-menu .mainmenu .has-droupdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a').removeClass('open')
});

// nav menu
$('.body-mobile .mainmenu .has-droupdown > a, .body-mobile .mainmenu .with-megamenu > a').on('click', function (e) {
    e.preventDefault();
    $(this).siblings('.submenu, .rts-megamenu').toggleClass('active');
    $(this).toggleClass('open')
})